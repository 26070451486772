import React from 'react'
import { Row, Col } from 'antd'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Helmet } from '@/components'
import { Box, useMediaQuery } from '@mui/material'
import { Footer, Header, SEO } from '@/components'

export default (props) => {
  const {} = useTranslation()
  const pageLocale = JSON.parse(props.data.locales.edges[0].node.data)
  const seoLocale = JSON.parse(props.data.seoLocale.data)
  const isPc = useMediaQuery('@media (min-width: 800px)')

  const {
    cspTitle,
    discription,
    keyBebefits,
    keyBebefitsList,
    whoCanApplyTitle,
    whoCanApplyDiscription1,
    whoCanApplyDiscription2,
    whoCanApplyList,
  } = pageLocale

  return (
    <>
      <SEO
        title={seoLocale.channelServicePartner.title}
        description={seoLocale.channelServicePartner.description}
        pageLocale={pageLocale}
      />
      <Helmet>
        <meta
          name='keywords'
          content='Careers at iMile, iMile jobs, Work for iMile, iMile delivery job, job opportunities iMile, Work at iMile'
        />

        <meta property='og:type' content='website' />
        <meta property='og:site_name' content='iMile Delivery' />
        <meta property='og:url' content='https://www.imile.com/csp/channelServicePartner' />
        <meta
          property='og:image'
          content='https://www.imile.com/static/1c763be06e4165a95c46d50a3039cf28/d5f9a/banner.webp'
        />
        <meta
          property='og:title'
          content='Unleash Your Potential: Partner with iMile for Global CSP Success'
        />
        <meta
          property='og:description'
          content='Calling all express delivery entrepreneurs and established businesses! Embrace the chance to connect with our international network through the iMile Global CSP Program.'
        />
      </Helmet>
      <Header
        location={props.location}
        headerLocale={JSON.parse(props.data.headerLocale.data)}
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
      />
      {/* banner*/}
      <Box
        className='banner'
        sx={{
          position: 'relative',
          '.bg-img': {
            height: '100%',
            mt: ['55px', '55px', '90px'],
            width: 1,
          },
        }}
      >
        {isPc ? (
          <Box component='img' src='/images/cspNewBanner.png' className='bg-img'></Box>
        ) : (
          <Box component='img' src='/images/cspBannerPhone.jpg' className='bg-img'></Box>
        )}
      </Box>
      {isPc ? (
        <Box>
          <Box sx={{ margin: '60px 80px' }}>
            <Box
              sx={{
                fontSize: '36px',
                fontWeight: 'bold',
                lineHeight: '52px',
                textAlign: 'center',
                color: '#000',
                marginBottom: '30px',
              }}
            >
              {cspTitle}
            </Box>
            <Box>{discription}</Box>
          </Box>
          <Box
            sx={{
              background: 'rgba(6, 44, 255, 0.04)',
              padding: '40px 80px 60px',
            }}
          >
            <Box
              sx={{
                fontSize: '28px',
                fontWeight: 'bold',
                lineHeight: '32px',
                marginBottom: '40px',
              }}
            >
              {keyBebefits}
            </Box>
            <Box sx={{ display: 'flex' }}>
              {keyBebefitsList.map((item) => (
                <Box
                  sx={{
                    width: '50%',
                    img: {
                      marginRight: '20px',
                    },
                    span: {
                      fontSize: '22px',
                      fontWeight: 'bold',
                      lineHeight: '28px',
                      color: '#000',
                    },
                    '&:nth-of-type(1)': {
                      paddingRight: '50px',
                      borderRight: '1px solid #D2D5DE',
                    },
                    '&:nth-of-type(2)': {
                      paddingLeft: '50px',
                    },
                  }}
                >
                  <Box component='img' src={item.path}></Box>
                  <span>{item.title}</span>
                  <Box>{item.discription}</Box>
                </Box>
              ))}
            </Box>
          </Box>
          <Box
            sx={{
              padding: '60px 80px 80px',
              '.card-show': {
                marginBottom: '50px',
              },
              '.ant-col-8': {
                padding: '0 10px',
              },
            }}
          >
            <Box sx={{ lineHeight: '32px', fontSize: '28px', fontWeight: 'bold' }}>
              {whoCanApplyTitle}
            </Box>
            <Box sx={{ lineHeight: '28px', fontSize: '16px', marginTop: '30px' }}>
              {whoCanApplyDiscription1}
            </Box>
            <Box sx={{ lineHeight: '28px', fontSize: '16px', marginBottom: '52px' }}>
              {whoCanApplyDiscription2}
            </Box>
            <Row>
              {whoCanApplyList.map((item) => (
                <Col span={8} className='card-show'>
                  <Box
                    sx={{
                      position: 'relative',
                      minHeight: '330px',
                      height: '100%',
                      borderRadius: ['20px', '32px'],
                      padding: '33px 24px 24px 30px',
                      bgcolor: '#fff',
                      mb: ['30px'],
                      mx: '10px',
                      boxShadow: '0px 6px 20px 0px rgba(0, 0, 0, 0.06)',
                      '&:after': {
                        content: '""',
                        position: 'absolute',
                        border: '4px solid #095ef7',
                        width: '100%',
                        minHeight: '330px',
                        height: '100%',
                        borderRadius: ['20px', '32px'],
                        left: '10px',
                        top: '-9px',
                        zIndex: -1,
                      },
                    }}
                  >
                    <Box
                      sx={{
                        fontSize: '24px',
                        lineHeight: '32px',
                        fontWeight: 'bold',
                        marginBottom: '19px',
                      }}
                    >
                      {item.title}
                    </Box>
                    <Box sx={{ fontSize: '16px', lineHeight: '28px' }}>{item.discription}</Box>
                  </Box>
                </Col>
              ))}
            </Row>
          </Box>
        </Box>
      ) : (
        <Box>
          <Box sx={{ margin: '3rem 1.6rem' }}>
            <Box
              sx={{
                fontSize: '1.4rem',
                fontWeight: 'bold',
                lineHeight: '52px',
                textAlign: 'center',
                color: '#000',
                marginBottom: '1.6rem',
              }}
            >
              {cspTitle}
            </Box>
            <Box>{discription}</Box>
          </Box>
          <Box
            sx={{
              background: 'rgba(6, 44, 255, 0.04)',
              padding: '2.5rem 1.5rem',
            }}
          >
            <Box
              sx={{
                fontSize: '1.2rem',
                fontWeight: 'bold',
                lineHeight: '32px',
                marginBottom: '2rem',
              }}
            >
              {keyBebefits}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              {keyBebefitsList.map((item) => (
                <Box
                  sx={{
                    width: '100%',
                    img: {
                      marginRight: '1rem',
                    },
                    span: {
                      fontSize: '1rem',
                      fontWeight: 'bold',
                      lineHeight: '28px',
                      color: '#000',
                    },
                    '&:nth-of-type(1)': {
                      borderBottom: '1px solid #D2D5DE',
                      paddingBottom: '1rem',
                    },
                    '&:nth-of-type(2)': {
                      paddingTop: '1rem',
                    },
                  }}
                >
                  <Box component='img' src={item.path}></Box>
                  <span>{item.title}</span>
                  <Box>{item.discription}</Box>
                </Box>
              ))}
            </Box>
          </Box>
          <Box
            sx={{
              padding: '3rem 1.6rem',
              '.card-show': {
                marginBottom: '2rem',
              },
              '.ant-col-8': {
                padding: '0 10px',
              },
            }}
          >
            <Box sx={{ lineHeight: '32px', fontSize: '1.3rem', fontWeight: 'bold' }}>
              {whoCanApplyTitle}
            </Box>
            <Box sx={{ lineHeight: '28px', fontSize: '0.9rem', marginTop: '30px' }}>
              {whoCanApplyDiscription1}
            </Box>
            <Box sx={{ lineHeight: '28px', fontSize: '0.9rem', marginBottom: '52px' }}>
              {whoCanApplyDiscription2}
            </Box>
            <Row>
              {whoCanApplyList.map((item) => (
                <Col span={24} className='card-show'>
                  <Box
                    sx={{
                      position: 'relative',
                      minHeight: '330px',
                      height: '100%',
                      borderRadius: ['20px', '32px'],
                      padding: '33px 24px 24px 30px',
                      bgcolor: '#fff',
                      mb: ['30px'],
                      mx: '10px',
                      boxShadow: '0px 6px 20px 0px rgba(0, 0, 0, 0.06)',
                      '&:after': {
                        content: '""',
                        position: 'absolute',
                        border: '4px solid #095ef7',
                        width: '100%',
                        height: '100%',
                        borderRadius: ['20px', '32px'],
                        left: '10px',
                        top: '-9px',
                        zIndex: -1,
                      },
                    }}
                  >
                    <Box
                      sx={{
                        fontSize: '24px',
                        lineHeight: '32px',
                        fontWeight: 'bold',
                        marginBottom: '19px',
                      }}
                    >
                      {item.title}
                    </Box>
                    <Box sx={{ fontSize: '16px', lineHeight: '28px' }}>{item.discription}</Box>
                  </Box>
                </Col>
              ))}
            </Row>
          </Box>
        </Box>
      )}
      <Footer
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
      />
    </>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { ns: { in: ["csp"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
        }
      }
    }
    commonLocale: locale(ns: { eq: "common" }, language: { eq: $language }) {
      data
    }
    headerLocale: locale(ns: { eq: "header" }, language: { eq: $language }) {
      data
    }
    footerLocale: locale(ns: { eq: "footer" }, language: { eq: $language }) {
      data
    }
    languageLocale: locale(ns: { eq: "language" }, language: { eq: $language }) {
      data
    }
    seoLocale: locale(ns: { eq: "seo" }, language: { eq: $language }) {
      data
    }
  }
`
